import React, { useContext } from 'react'
import cn from 'classnames'
import { Dialog, Transition, TransitionChild } from '@headlessui/react'
import IconX from 'assets/icons/x.svg'
import { ErrorModalContext, ModalContext } from './ModalContext'

export function ModalContent({
  title,
  children,
  ...props
}: Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> & {
  title?: React.ReactNode
  children: React.ReactNode
  ['data-testid']: string
}) {
  return (
    <div
      className={cn(
        { '[&~button]:text-white': Boolean(title) },
        { '[&~button]:text-enpal-sky-blue': !title }
      )}
      {...props}
    >
      {title && (
        <div
          className={cn(
            'flex',
            'items-center',
            'bg-enpal-sky-blue',
            'justify-center',
            'text-white',
            'text-xl sm:text-2xl',
            'p-8',
            'font-bold',
            'relative'
          )}
        >
          <>{title}</>
        </div>
      )}

      <div
        className={cn(
          'px-4 sm:px-20',
          'pt-3 sm:pt-7',
          'pb-7',
          'text-lg sm:text-2xl'
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default function Modal({ context } = { context: ModalContext }) {
  const { isOpen, modalContent, closeModal, closable } = useContext(context)
  const { isOpen: isErrorModalOpen, modalContent: errorModalContent } =
    useContext(ErrorModalContext)

  function handleCloseByUser() {
    if (modalContent !== errorModalContent && isErrorModalOpen) return
    if (!closable) return
    closeModal()
  }

  return (
    <Transition show={isOpen} appear={true} as={React.Fragment}>
      <Dialog
        onClose={handleCloseByUser}
        className="pt-10 pb-32 sm:pb-0 sm:pt-0 fixed z-40 inset-0 overflow-y-auto flex items-center justify-center min-h-screen"
      >
        <TransitionChild
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            data-testid="modal-backdrop"
            className="fixed inset-0 bg-enpalBlack bg-opacity-75 backdrop-blur backdrop-filter"
          />
        </TransitionChild>
        <TransitionChild
          as={React.Fragment}
          enter="transition ease-out duration-300"
          enterFrom="transform opacity-0 scale-0"
          enterTo="opacity-100 scale-1"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-1"
          leaveTo="opacity-0 transform scale-0"
        >
          <div
            className={cn(
              'flex',
              'flex-col',
              'relative',
              'justify-self-center',
              'shadow-booking',
              'md:w-[724px]',
              'max-w-full',
              'my-auto',
              'mx-5',
              'md:mx-auto',
              'shadow-lg',
              'rounded-2xl',
              'bg-white',
              'overflow-hidden'
            )}
          >
            {modalContent}
            {closable && (
              <button
                data-testid="modal-close"
                className="top-3 right-3 sm:top-4 sm:right-4 absolute"
                onClick={handleCloseByUser}
              >
                <IconX width="24px" height="24px" />
              </button>
            )}
          </div>
        </TransitionChild>
      </Dialog>
    </Transition>
  )
}
